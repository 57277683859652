.RichEditor-root {
  font-family: "Source sans pro", serif;
  font-size: 14px;
  padding: 15px;

  width: 100%;
  height: 100%;
  text-indent: 10px;
  margin: 10px 0px;

  border: none;
  border-radius: 5px;

  -webkit-box-shadow: 0px 0px 24px 0px rgba(50, 50, 50, 0.2);
  -moz-box-shadow: 0px 0px 24px 0px rgba(50, 50, 50, 0.2);
  box-shadow: 0px 0px 24px 0px rgba(50, 50, 50, 0.2);
}

.RichEditor-editor {
  width: 100%;
  height: 100%;
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
  text-align: left;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 0px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: "Hoefler Text", "Georgia", serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.public-DraftEditorPlaceholder-inner {
  color: grey;
  text-indent: 30px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: "Inconsolata", "Menlo", "Consolas", monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: "Helvetica", sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}
