.nav-container {
  position: relative;
}

.sidebar-nav {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  top: 5px;
  left: 15px;
  position: absolute;

  display: none;

  z-index: 1000;
  box-shadow: 7px 7px 23px 0px rgba(50, 50, 50, 0.54);
}

.navbar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 30px;
  background-color: white;
  display: flex;
  position: fixed;
  justify-content: flex-start;
  align-items: center;
}

.menu-bars {
  font-size: 1.5rem;
  color: white;
  background: none;
}

.menu-bars:hover {
  color: #67c7ba;
}

.nav-menu {
  background-color: #dc3163;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;

  z-index: 1000;

  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  background-color: #dc3163;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;

  transition: 850ms;
}

.nav-text {
  text-transform: capitalize;
  list-style: none;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-content: 8px 0px 8px 16px;
}

.nav-text a {
  color: #f5f5f5;
  font-size: 18px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.nav-text a:hover {
  background-color: #67c7ba;
  text-decoration: none;
  color: white;
}

.nav-menu-items {
  width: 100%;
}

.nav-bar-toggle {
  text-align: right;
  padding-right: 10px;
}

span {
  margin-left: 16px;
}

ul {
  padding: 0;
}

/* RESPONSIVE  */

@media (max-width: 870px) {
  .sidebar-nav {
    display: block;
    position: fixed;

    z-index: 1000;
  }
}

@media (max-height: 350px) {
  .nav-text {
    height: 40px;
  }
}

@media (max-height: 400px) {
  .nav-text {
    height: 50px;
  }
}
